import { ChevronRightIcon } from '@heroicons/react/20/solid'
import {
  BookOpenIcon,
  ComputerDesktopIcon,
} from '@heroicons/react/24/solid'
import Link from 'next/link'
import Image from 'next/image'

import iconCapsule from '@/images/brand/icon.png'
import Head from 'next/head'

const links = [
  {
    title: 'Capsule App',
    description: 'Create your first Capsule',
    icon: ComputerDesktopIcon,
    href: 'https://app.capsulenft.com/',
  },
  {
    title: 'Documentation',
    description: 'Learn how to integrate our tools with your app',
    icon: BookOpenIcon,
    href: 'https://docs.capsulenft.com',
  },
]

export default function Custom404() {
  return (
    <>
      <Head>
        <title>404 - Capsule</title>
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        <meta name="description" content="This page does not exist." />
      </Head>
      <div className="mx-auto w-full max-w-7xl bg-capsule-dark px-4 sm:px-6 lg:px-8">
        <div className="flex-shrink-0 pt-16">
          <Image
            className="mx-auto"
            src={iconCapsule}
            alt=""
            width={128}
            placeholder="blur"
            priority
          />
        </div>
        <div className="mx-auto max-w-xl pt-6 sm:py-24">
          <div className="text-center">
            <p className="text-base font-semibold text-capsule-purple">404</p>
            <h1 className="mt-2 text-4xl font-bold tracking-tight text-white sm:text-5xl">
              This page does not exist.
            </h1>
            <p className="mt-2 text-lg text-white">
              The page you are looking for could not be found.
            </p>
          </div>
          <div className="mt-12">
            <h2 className="text-base font-semibold text-white">
              Popular pages
            </h2>
            <ul
              role="list"
              className="mt-4 divide-y divide-zinc-600 border-t border-b border-zinc-600"
            >
              {links.map((link, linkIdx) => (
                <li
                  key={linkIdx}
                  className="group relative flex items-start space-x-4 py-6 hover:bg-capsule-purple/60"
                >
                  <div className="flex-shrink-0">
                    <span className="flex h-12 w-12 items-center justify-center rounded-lg">
                      <link.icon
                        className="h-6 w-6 text-zinc-500 group-hover:text-white"
                        aria-hidden="true"
                      />
                    </span>
                  </div>
                  <div className="min-w-0 flex-1">
                    <h3 className="text-base font-medium text-white">
                      <span className="rounded-sm focus-within:bg-capsule-purple focus-within:ring-2 focus-within:ring-offset-2">
                        {link.href.startsWith('https') ? (
                          <a
                            href={link.href}
                            className="font-bold focus:outline-none"
                          >
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            />
                            {link.title}
                          </a>
                        ) : (
                          <Link
                            href={link.href}
                            className="font-bold focus:outline-none"
                          >
                            <span
                              className="absolute inset-0"
                              aria-hidden="true"
                            />
                            {link.title}
                          </Link>
                        )}
                      </span>
                    </h3>
                    <p className="text-base text-zinc-400">
                      {link.description}
                    </p>
                  </div>
                  <div className="flex-shrink-0 self-center">
                    <ChevronRightIcon
                      className="h-5 w-5 text-white"
                      aria-hidden="true"
                    />
                  </div>
                </li>
              ))}
            </ul>
            <div className="mt-8">
              <Link
                href="/"
                className="text-base font-medium text-capsule-purple hover:text-capsule-purple"
              >
                Or go back home
                <span aria-hidden="true"> &rarr;</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
